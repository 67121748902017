import type { PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { PageLayout } from '../layouts/page-layout';
import withAppInsights from '../utils/analytics/appinsights';

export type MarkdownPageContext = {
  title: string;
  description: string;
  body: string;
  isLargeContent: boolean;
  commitDate: number;
};

export function MarkdownTemplate({ pageContext, location }: PageProps<null, MarkdownPageContext>) {
  const { isLargeContent, title, description, commitDate, body } = pageContext;

  return (
    <div data-testid="markdown-template">
      <PageLayout
        location={location}
        isLargeContent={isLargeContent as unknown as boolean}
        title={title}
        description={description}
        commitDate={commitDate}
      >
        <MDXRenderer>{body}</MDXRenderer>
      </PageLayout>
    </div>
  );
}

export default withAppInsights(MarkdownTemplate);
